<template>
  <div class="contract_list">
    <div class="globle_border" v-loading="loading">
      <div class="nav_wrap">
        <back class="back" />
        <div class="search">
          <el-select class="status_list" v-model="platformStatus" placeholder="平台签署状态" @change="onSearch" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select class="status_list" v-model="supplierStatus" placeholder="供应商签署状态" @change="onSearch" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select class="status_list" v-model="shopStatus" placeholder="商超签署状态" @change="onSearch" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select class="status_list" v-model="contractStatus" placeholder="合同状态" @change="onSearch" clearable>
            <el-option v-for="item in contract" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <!-- <el-input v-model="tendersName" placeholder="请输入招标名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input> -->
          <el-input v-model="supplierName" placeholder="请输入供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-input v-model="shopCompany" placeholder="请输入商超名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-button type="primary" @click="handleAddAll">一键签署</el-button>
        </div>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="contractNum" label="合同编号" show-overflow-tooltip min-width="120"></el-table-column>
          <el-table-column prop="contractName" label="合同名称" show-overflow-tooltip min-width="200"></el-table-column>
          <el-table-column prop="contractName" label="合同有效期" show-overflow-tooltip min-width="200">
            <template slot-scope="scope">{{ scope.row.startTime }}至{{ scope.row.endTime }}</template>
          </el-table-column>
          <el-table-column prop="bidPeriod" label="标期" show-overflow-tooltip min-width="100"></el-table-column>
          <el-table-column prop="tendersLabel" label="标号" show-overflow-tooltip min-width="100"></el-table-column>
          <el-table-column prop="tendersName" label="招标名称" show-overflow-tooltip width="200"></el-table-column>
          <!-- <el-table-column prop="depositAmount" label="履约保证金(元)" show-overflow-tooltip width="120"> </el-table-column> -->
          <el-table-column prop="supplierName" label="供应商名称" show-overflow-tooltip width="200"></el-table-column>
          <el-table-column prop="shopCompany" label="商超名称" show-overflow-tooltip width="200"></el-table-column>
          <!-- <el-table-column prop="contractType" label="合同类型" show-overflow-tooltip width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.contractType == 1">商超招标合同</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="platformStatus" label="平台签署状态" show-overflow-tooltip width="120" align="center">
            <template slot-scope="scope">
              <span :class="scope.row.platformStatus == 0 ? 'red' : ''">{{ scope.row.platformStatus == 0 ? "未签署" : "已签署" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="supplierStatus" label="供应商签署状态" show-overflow-tooltip width="120" align="center">
            <template slot-scope="scope">
              <span :class="scope.row.supplierStatus == 0 ? 'red' : ''">{{ scope.row.supplierStatus == 0 ? "未签署" : "已签署" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="shopStatus" label="商超签署状态" show-overflow-tooltip width="120" align="center">
            <template slot-scope="scope">
              <span :class="scope.row.shopStatus == 0 ? 'red' : ''">{{ scope.row.shopStatus == 0 ? "未签署" : "已签署" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="contractStatus" label="合同状态" align="center">
            <template slot-scope="scope">
              <span :class="scope.row.contractStatus == 0 ? 'red' : ''">{{ scope.row.contractStatus == 0 ? "未完成" : "已完成" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="240" align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.contractStatus == 0 && scope.row.platformStatus == 0" class="btn" type="primary" size="" @click="handleSign(scope.$index, scope.row)">签署</el-button>
              <el-button v-if="showConfirm(scope.row)" class="btn" type="primary" size="" @click="handleConfirm(scope.$index, scope.row)">确认合同</el-button>
              <el-button class="btn" type="primary" size="" plain @click="download(scope.$index, scope.row)">下载</el-button>
              <el-button class="btn" type="primary" size="" plain @click="handleView(scope.$index, scope.row)">查看</el-button>
              <!-- <el-button v-if="scope.row.contractStatus == 0 && scope.row.supplierStatus == 0 && scope.row.platformStatus == 0" class="btn" type="danger" size="" plain @click="handleDelete(scope.$index, scope.row)">撤回</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-dialog :title="signtitle" v-loading="signLoading" element-loading-text="合同正在签署" :visible.sync="dialogVisible" :close-on-click-modal="false" :before-close="onCancel">
      <el-empty v-if="signList.length == 0" description="暂无签章"></el-empty>
      <div v-else class="signs">
        <div class="label">选择签章：</div>
        <div class="items">
          <div class="item" v-for="(item, index) in signList" :key="index" :class="item.id == signatureId ? 'is_ac' : ''" @click="changeSignId(item.id)">
            <img :src="item.signatureUrl" width="120" height="120" />
            <div class="name">{{ item.signatureTypeName }}</div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onSignAll">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      submitLoding: false,
      isAdd: true,
      tendersName: "",
      supplierName: "",
      shopCompany: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      contractStatus: "", //合同状态
      supplierStatus: "", //供应商签署状态
      shopStatus: "", //供应商签署状态
      platformStatus: "", //平台签署状态
      contract: [
        { value: 0, label: "未完成" },
        { value: 1, label: "已完成" },
      ],
      options: [
        { value: 0, label: "未签署" },
        { value: 1, label: "已签署" },
      ],

      signList: [],
      signatureId: "", // 签章id
      contractId: "", // 合同id
      isActive: "",
      dialogVisible: false,
      signLoading: false,
      signtitle: "签署",
    }
  },
  created() {
    this.contractsList()
    this.getSignList()
  },
  methods: {
    // 列表
    contractsList() {
      this.loading = true
      this.$axios
        .get(this.$api.contractsList, {
          params: {
            tendersId: this.$route.query.id,
            page: this.currentPage,
            pageSize: this.pageSize,
            tendersName: this.tendersName,
            supplierName: this.supplierName,
            shopCompany: this.shopCompany,
            contractStatus: this.contractStatus, //合同状态
            supplierStatus: this.supplierStatus, //供应商签署状态
            shopStatus: this.shopStatus, //商超签署状态
            platformStatus: this.platformStatus, //平台签署状态
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    // 获取签章
    getSignList() {
      this.loading = true
      this.$axios
        .get(this.$api.selectSignatureInfoList, {
          params: {
            page: 1,
            pageSize: 999,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.signList = res.data.result.list
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.contractsList()
    },
    handleAddAll() {
      this.isActive = ""
      this.contractId = ""
      this.signatureId = ""
      this.signtitle = "一键签署"
      this.dialogVisible = true
    },
    changeSignId(id) {
      this.signatureId = id
    },
    onCancel() {
      this.signatureId = ""
      this.dialogVisible = false
    },
    // 查看
    handleView(index, row) {
      localStorage.setItem("fileUrl", row.fileUrl)
      window.open(row.fileUrl)
      // this.$router.push({ path: "/contract/detail/preview" })
    },
    // 下载
    download(index, row) {
      window.open(row.fileUrl)
    },
    // 是否显示确认合同按钮
    showConfirm(row) {
      if (row.platformStatus == 1 && row.supplierStatus == 1 && row.shopStatus == 1 && row.contractStatus == 0) {
        return true
      } else {
        return false
      }
    },
    // 确认
    handleConfirm(index, row) {
      this.$confirm("是否确认该合同三方均已签署？", "合同确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        center: true,
      })
        .then(() => {
          this.$axios.post(this.$api.contractConfirm + row.id).then(res => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.contractsList()
          })
        })
        .catch(() => {})
    },
    // 一键签署or签署提交
    onSignAll() {
      if (this.signtitle == "签署") {
        if (this.signatureId) {
          this.$axios
            .post(this.$api.signTendersContract, {
              contractId: this.contractId,
              signatureId: this.signatureId,
            })
            .then(res => {
              if (res.data.code == 100) {
                this.signLoading = true
                setTimeout(() => {
                  this.$message.success("签署成功")
                  this.signLoading = false
                  this.dialogVisible = false
                  this.contractsList()
                }, 2000)
              }
            })
        } else {
          this.$message.error("请选择签章")
        }
      }
      if (this.signtitle == "一键签署") {
        if (this.signatureId) {
          this.$axios
            .post(this.$api.signTendersContract, {
              tendersId: this.$route.query.id,
              signatureId: this.signatureId,
            })
            .then(res => {
              if (res.data.code == 100) {
                this.signLoading = true
                setTimeout(() => {
                  this.$message.success("签署成功")
                  this.signLoading = false
                  this.dialogVisible = false
                  this.contractsList()
                }, 6000)
              }
            })
        } else {
          this.$message.error("请选择签章")
        }
      }
    },
    // 签署弹窗
    handleSign(index, row) {
      localStorage.setItem("fileUrl", row.fileUrl)
      this.signtitle = "签署"
      this.contractId = row.id
      this.signatureId = ""
      this.dialogVisible = true
      // window.open(row.fileUrl)
      // this.$router.push({ path: "/contract/detail/sign", query: { id: row.id } })
    },
    // // 撤回
    // handleDelete (index, row) {
    //   this.$confirm('撤回合同后视为作废不允许再次启用，是否确认撤回？', '撤回', {
    //     confirmButtonText: '确认撤回',
    //     cancelButtonText: '取消',
    //     type: 'warning',
    //     center: true
    //   }).then(() => {
    //     this.$axios.post(this.$api.contractRetract + row.id).then((res) => {
    //       if (res.data.code == 100) {
    //         this.$message.success(res.data.desc)
    //       }
    //       this.contractsList()
    //     })
    //   }).catch(() => { });
    // },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.contractsList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.contractsList()
    },
  },
}
</script>

<style scoped lang="scss">
.contract_list {
  .nav_wrap {
    display: flex;
    justify-content: space-between;

    .back {
      padding: 0 !important;
      margin-bottom: 10px;
    }
  }

  .search {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;

    .el-input {
      width: 160px;

      ::v-deep .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-select,
    .el-input,
    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .status_list {
    width: 150px;
  }

  .red {
    color: red;
  }

  .grey {
    color: #03100e99;
  }

  .signs {
    display: flex;
    justify-content: center;
    width: 500px;
    margin: 0 auto;

    .label {
      width: 100px;
      margin-right: 20px;
      text-align: center;
    }

    .items {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      grid-gap: 20px;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px solid #eee;
        border-radius: 10px;
        text-align: center;
        padding: 10px;
        cursor: pointer;

        .name {
          color: rgba(3, 16, 14, 0.6);
          margin-top: 8px;
        }
      }

      .is_ac {
        border-color: #069bbc;
      }
    }
  }
}
</style>
